import React from 'react'
import Helmet from 'react-helmet'

// TODO: use Microdata or JSON-LD
//

export default () => (
    <div>
        <Helmet title="Contact" />
        <p>
            Your Contact: Tanja Vohalski Adamić, Master of English Language and
            Literature and Master of Art History
        </p>
    </div>
)
